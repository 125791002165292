<template>
  <div>
    <importVehicle />
  </div>
</template>
<script>
import importVehicle from "@/views/boss/transport/vehicleManage/importVehicle";
export default {
  data() {
    return {

    }
  },
  components: {
    importVehicle,
  },
}
</script>